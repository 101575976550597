.clickable {
  cursor: pointer;
}
.tablepagebtn {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .tablepagebtn {
    flex-direction: column;
    align-items: center;
  }
}
